<template>
  <div class="page-main-bg" id="clientsPage">
    <v-container
      :class="[
        'main-container team-container position-relative',
        { 'list-view': !gridView, 'grid-view': gridView }
      ]"
    >
      <!-------------------------------------------->
      <!-------------Toolbar------------------------>
      <!-------------------------------------------->

      <tool-bar
        v-bind="toolBarProps"
        @toggleCards="cardsInfoState = !cardsInfoState"
        @setDate="setDate"
      />

      <!-------------------------------------------->
      <!-------------ClientStat--------------------->
      <!-------------------------------------------->
      <v-slide-y-transition>
        <clientStat
          v-if="$hasPermission('statistics', 'statistics', 'list')"
          v-show="cardsInfoState"
          statesType="clients"
          :datesQuery="datesQuery"
        />
      </v-slide-y-transition>

      <!------------------------------------------->
      <!-------------Filters----------------------->
      <!------------------------------------------->
      <client-filter
        ref="filter"
        v-bind="{ status: filterStatus, statusRestore, gridView }"
        @switched="switched"
        @search="searchClients"
      >
        <!------------------------------------>
        <!----MultipleSelectionsForDelete----->
        <!------------------------------------>

        <div class="d-flex">
          <MultipleSelectionsForDelete
            v-bind="multipleSelectionsData"
            :statusBackLog="filterStatus.backLog"
            :statusclaims="filterStatus.claims"
            :statusHaveContracts="filterStatus.haveContracts"
            :showRestoreDeleted="false"
            @changeSelect="selectAllChanged"
            @switched="switched"
          />
          <!-- INFO: was for the export data befour remove it  -->
        </div>
      </client-filter>

      <!-------------Loader---------------->
      <Loader v-if="isPageLoading" v-bind="loaderProps" />

      <!-------------------------------------------->
      <!---------- If no there any client ---------->
      <!-------------------------------------------->
      <v-col v-else-if="!userLength">
        <v-sheet
          class="pa-10 d-flex align-center justify-center flex-column bg-primary"
          height="60vh"
        >
          <span class="grey--text text--lighten-1 mb-4" style="font-size: 80px">
            <img
              src="../assets/img/svgs/Hectar icon/user 2.svg"
              alt=""
              width="50"
              loading="lazy"
            />
          </span>
          <h3 class="grey--text text--darken-3 my-0" v-text="noData.title" />
          <h5 class="grey--text" v-text="noData.subTitle" />
        </v-sheet>
      </v-col>

      <!----------------------------->
      <!---- Data table client ------>
      <!----------------------------->

      <v-row class="mt-6" v-else-if="gridView">
        <v-col
          v-for="user in users"
          :key="user._id"
          :lg="3"
          :md="4"
          :sm="6"
          class="property-col relative set__hover"
          cols="12"
        >
          <ClientCard
            :class="{ border__card: user.checked }"
            :totalCount="user"
            :statusclaims="filterStatus.claims"
            :user="user | renderInfoUser"
            @edit-client="editClient"
          />
          <v-checkbox
            v-if="isDeletable(user)"
            :class="[
              'reset-all ma-0 checkbox checkbox__client',
              { checked: user.checked }
            ]"
            dense
            hide-details
            v-model="user.checked"
          />
        </v-col>
      </v-row>
      <v-row class="mt-6" v-else>
        <v-col class="property-col" cols="12">
          <data-table-client
            :headers="headers"
            :users="dataTableUsers"
            @edit-client="editClient"
          />
        </v-col>
      </v-row>

      <!----------------------------->
      <!------ Add client modal ----->
      <!----------------------------->

      <AddClientModal
        :dialogState="clients.dialogState"
        :clientData="clientInfo"
        :editClientId="clientId"
        @close-client-dialog="closeModals"
        @refresh-clients-data="refreshClientsData"
      />

      <!------------------------------------------------------->
      <!-------User actions (addClient & fastActions)---------->
      <!------------------------------------------------------->

      <div class="d-flex align-center justify-end ml-md-8 fast-actions">
        <v-btn
          data-test="openModal"
          @click="openAddDialog"
          class="font-weight-bold fast-actions-btn pa-5 font-12"
          color="primary"
          large
        >
          <img class="mx-2" src="@/assets/img/svgs/user-w.svg" loading="lazy" />
          إضافة عميل
        </v-btn>

        <!----------------------------->
        <!-------Fast actions---------->
        <!----------------------------->
        <fastActions />
      </div>

      <!------------------------------>
      <!--------Pagination------------>
      <!------------------------------>

      <div class="text-center mt-6 mb-5" v-show="viewPagination">
        <Pagination
          :pagination="pagination"
          @onChange="handleOnchangePagination"
        />
      </div>
    </v-container>
  </div>
</template>

<style>
.v-icon.v-icon--dense {
  font-size: 25px !important;
}
</style>
<script>
import { mapMutations, mapState } from 'vuex'
import { clients } from '@/constans/clients'
import { EnvService, userService, MultipleDeletion } from '@/services'
import multipleSelections from '@/mixins/multipleSelections'
import setView from '@/mixins/setView'
import ClientCard from '@/components/clients/ClientCard.vue'
import ToolBar from '@/components/listing/ToolBar'
import ClientFilter from '@/components/clients/ClientFilter'
import DataTableClient from '@/components/clients/DataTableClient'
import Pagination from '@/components/helper/pagination.vue'
import clientStat from '@/components/properties/RealEstatStat'
import Loader from '@/components/helper/loader.vue'
import fastActions from '@/components/helper/fastActions'
import MultipleSelectionsForDelete from '@/components/helper/MultipleSelectionsForDelete'
import '@/styles/material-dashboard/_properties.scss'
import '@/styles/material-dashboard/_team.scss'

export default {
  components: {
    AddClientModal: () => import('@/components/modals/AddClientModal'),
    fastActions,
    DataTableClient,
    ClientFilter,
    ToolBar,
    ClientCard,
    Pagination,
    clientStat,
    Loader,
    MultipleSelectionsForDelete
  },
  mixins: [multipleSelections, setView],
  data() {
    return {
      ...clients,
      datesQuery: {
        startDate: '',
        endDate: ''
      },
      upload: require('@/assets/img/export-data/upload-grey.svg'),
      clientSwitcher: false,
      cardsInfoState: true,
      isPageLoading: false,
      gridView: true,
      clients: {},
      clientsCount: 0,
      pagination: {
        pageNumber: 1,
        pagesCount: 0,
        pageSize: 12,
        count: 0
      },
      clientInfo: {},
      clientId: null,
      noData: {},
      breadcrumbs: [
        {
          text: 'قائمة العملاء',
          disabled: true,
          to: { path: '/dashboard/contracts' }
        }
      ],
      checkedHeaders: [
        'اسم العميل',
        'رقم العميل',
        'نوع العميل',
        'نوع الحساب',
        'رقم الهوية / رقم المنشأة',
        'رقم الجوال',
        'المستحقات'
      ],
      users: [],
      FastActions: false,
      filterCode: null,
      filterStatus: {},
      searchFilter: ''
    }
  },
  mounted() {
    this.$emit('updateInformativeTip', this.$route?.meta?.informativeTip)
    this.handleRetrievingData()
  },
  filters: {
    renderInfoUser(user) {
      if (user.renter) {
        return user.renter
      }
      if (user.user) {
        return user.user
      }
      return user
    }
  },
  computed: {
    ...mapState('accountState', ['currentEnv', 'user']),
    toolBarProps() {
      return {
        breadcrumbs: this.breadcrumbs,
        length: this.users.length,
        showDateFilter: true,
        cardsInfoState: this.cardsInfoState,
        title: 'العملاء'
      }
    },
    loaderProps() {
      return this.gridView
        ? {
            type: 'card',
            numberOfLines: 8,
            laoderClasses: 'mx-auto'
          }
        : {
            numberOfLines: 5,
            laoderClasses: 'my-2 shadow-border-radius pa-0',
            type: 'list-item-avatar-two-line',
            cols: 12
          }
    },
    userLength() {
      return !!this.users.length
    },
    headersArr() {
      let headerOne = [
        {
          text: 'اسم العميل',
          align: 'start',
          sortable: false,
          value: 'name'
        },
        { text: 'نوع الحساب', value: 'clientType' },
        { text: 'رقم العميل', value: 'clientNumber' },
        { text: 'نوع العميل', value: 'environments' },
        { text: 'رقم الهوية / رقم المنشأة', value: 'idNumber' },
        { text: 'رقم الجوال', value: 'phoneNumber' },
        { text: 'المستحقات', value: 'payments' },
        {
          text: 'تحديد',
          value: 'checked'
        }
      ]
      if (!this.clientSwitcher || this.filterStatus.haveContracts) {
        headerOne = headerOne.filter((col) => {
          return col.value !== 'payments'
        })
      }
      return headerOne
    },
    headers() {
      const arr = [
        {
          text: 'تحديد',
          value: 'checked'
        }
      ]
      this.headersArr.map((header) => {
        if (this.checkedHeaders.includes(header.text)) {
          return arr.push(header)
        }
        return null
      })
      return arr
    },
    viewPagination() {
      return this.pagination.pagesCount > 1
    },
    hasPermission() {
      return this.$hasPermission('users', 'clients', 'add')
    },
    dataTableUsers() {
      if (this.clientSwitcher && !this.isPageLoading) {
        return this.users.map((user) => {
          delete user.renter?._id
          delete user.user?._id
          return Object.assign(user, { ...user?.renter, ...user?.user })
        })
      }
      return this.users
    },
    /* Multiple selections */
    // ------------------ //
    // For multiple selection delete and restore
    deleteTitle() {
      return this.showSelectAll ? 'حذف العملاء' : 'حذف العميل'
    },
    restoreTitle() {
      return this.showSelectAll
        ? 'استرجاع العملاء المحذوفين'
        : 'استرجاع العميل المحذوف'
    },
    fqForLoadClients() {
      const searchQuery = this.searchFilter
        ? `&search=${this.searchFilter}`
        : ''
      return {
        filter:
          this.filterCode && this.filterCode !== 'total'
            ? `role=${this.filterCode}`
            : '',
        fContracts: 'hasContracts=1',
        query: `pageSize=${this.pagination.pageSize}&pageNumber=${this.pagination.pageNumber}${searchQuery}`
      }
    }
  },
  methods: {
    ...mapMutations('appState', ['toggleDrawer', 'addNotification']),
    setDate({ dateType, date }) {
      this.cardsInfoState = true
      this.datesQuery[`${dateType}`] = date
    },
    handleRetrievingData() {
      if (this.$route.query.filter) {
        this.filterStatus[this.$route.query.filter] = true
        this.$refs?.filter?.$refs?.[`${this.$route.query.filter}Ref`].onChange()
      } else {
        this.loadClients()
      }
    },
    refreshClientsData() {
      this.loadClients()
      this.getUserBundleFeatures()
    },
    async getUserBundleFeatures() {
      const { data } = await userService.getUserBundle(this.currentEnv._id)
      this.userBundleFeature = data.userBundle.features
    },
    async loadClients(filter = this.fqForLoadClients.filter) {
      this.isPageLoading = true
      try {
        const { data } = await EnvService.getAllClients(
          this.currentEnv._id,
          filter,
          this.fqForLoadClients.query
        )
        if (data.users.length === 0) {
          this.noData = {
            title: 'لا يوجد لديك عملاء حتى الآن',
            subTitle: 'اضف اول عميل لك الان'
          }
        }
        this.recalculatePagination(data)
        this.users = data.users.map((user) => ({ ...user, checked: false }))
        this.clientsCount = data.count
      } catch {
        this.addNotification({
          text: 'خطا في تحميل العملاء',
          color: 'error'
        })
      } finally {
        this.isPageLoading = false
      }
    },
    async clientOverdue() {
      try {
        this.isPageLoading = true
        const query = `environment=${this.currentEnv._id}&pageSize=${this.pagination.pageSize}&pageNumber=${this.pagination.pageNumber}`
        //
        const { data } = await userService.clientOverdue(query)
        if (data.users.length === 0) {
          this.noData = {
            title: 'لا يوجد لديك متأخرات حتى الآن'
          }
        }
        this.recalculatePagination(data)
        this.users = data.users.map((user) => ({ ...user, checked: false }))
      } catch {
        this.addNotification({
          text: 'خطا في تحميل العملاء المتأخرين',
          color: 'error'
        })
      } finally {
        this.isPageLoading = false
      }
    },
    async allDeletedClients() {
      try {
        this.isPageLoading = true
        const query = `environment=${this.currentEnv._id}&pageSize=${this.pagination.pageSize}&pageNumber=${this.pagination.pageNumber}`

        const { data } = await userService.clientOverdue(query)
        if (data.users.length === 0) {
          this.noData = {
            title: 'لا يوجد لديك عملاء محذوفين حتى الآن'
          }
        }
        this.recalculatePagination(data)
        this.users = data.users.map((user) => ({ ...user, checked: false }))
      } catch {
        this.addNotification({
          text: 'خطا في تحميل العملاء المحذوفين',
          color: 'error'
        })
      } finally {
        this.isPageLoading = false
      }
    },
    async allClaims() {
      try {
        this.isPageLoading = true
        const query = `pageSize=${this.pagination.pageSize}&pageNumber=${this.pagination.pageNumber}`
        const body = { environmentId: this.currentEnv._id }

        const { data } = await userService.getAllClaims(query, body)
        if (data.users.length === 0) {
          this.noData = {
            title: 'لا يوجد لديك مطالبات حتى الآن'
          }
        }
        this.recalculatePagination(data)
        this.users = data.users
      } catch {
        this.addNotification({
          text: 'خطا في تحميل المطالبات',
          color: 'error'
        })
      } finally {
        this.isPageLoading = false
      }
    },
    /* Multiple selections */
    // ------------------ //
    // Delete clients with multiple selections
    async deleteItems() {
      const title = this.showSelectAll ? 'حذف العملاء' : 'حذف العميل'
      const message = this.showSelectAll
        ? `هل انت متاكد من حذف العملاء وعددهم ${this.getAllChecked.length} يرجى العلم انه يمكن لا يتم حذفهم اذا كان لديهم عقارات`
        : 'هل انت متاكد من حذف العميل يرجى العلم انه يمكن لا يتم حذفه اذا كان لدية عقارات'

      // 1 - Open popup and confirm delete or not
      const status = await this.$root.$confirm.open({
        title,
        message,
        options: {
          color: 'red'
        }
      })

      // 2 - If confirmed
      if (status) {
        try {
          const body = { ids: this.getAllChecked }

          await MultipleDeletion.deleteClients(this.currentEnv._id, body)
          this.loadClients()
          this.addNotification({
            text: 'تم الحذف بنجاح',
            color: 'success'
          })
        } catch {
          this.addNotification({
            text: 'لا يمكنك حذف العميل(العملاء)، يجب حذف عقاراته(عقاراتهم) أولا',
            color: 'error'
          })
        }
      }
    },
    // Restore clients with multiple selections
    async restoreDelete() {
      const message = this.showSelectAll
        ? `هل انت متاكد من استرجاع العملاء المحذوفين وعددهم ${this.getAllChecked.length}`
        : 'هل انت متاكد من استرجاع العميل المحذوف'
      //
      const status = await this.$root.$confirm.open({
        title: this.restoreTitle,
        message,
        statusRestore: true,
        options: {
          color: 'red'
        }
      })
    },
    // Pagination - recalculate and handle when clicked on pagination
    recalculatePagination(res) {
      this.pagination.pagesCount = Math.ceil(
        res.count / this.pagination.pageSize
      )
      this.pagination.count = res.count
    },
    handleOnchangePagination() {
      if (this.filterStatus.backLog) {
        return this.clientOverdue()
      }
      if (this.statusRestore) {
        return this.allDeletedClients()
      }
      if (this.filterStatus.claims) {
        return this.allClaims()
      }
      if (this.filterStatus.haveContracts) {
        return this.loadClients(this.fqForLoadClients.fContracts)
      }
      return this.loadClients()
    },
    // Other
    openAddDialog() {
      if (!this.hasPermission) {
        this.addNotification({
          text: 'ليس لديك صلاحية لإضافة عملاء',
          color: 'error'
        })
        return
      }

      this.clients.dialogState = true
      this.clientInfo = {}
      this.clientId = null
    },
    editClient(clientInfo) {
      this.clients.dialogState = true
      this.clientId = clientInfo._id
      this.clientInfo = clientInfo
    },
    filterChanged(filter) {
      this.filterCode = filter
      this.pagination.pageNumber = 1
      this.loadClients()
    },
    changeGridView(status) {
      this.updateSetTableView(this.$route.name, status)
      this.gridView = status
    },
    closeModals() {
      this.clients = {}
    },
    // This will handle all cases switching
    switched(data) {
      this.users = []
      this.pagination.pageNumber = 1
      const { name, value } = data

      this.clientSwitcher = value
      this.filterStatus = {}
      this.statusRestore = false

      return this.clientSwitcher
        ? this.setSwitchedData(name)
        : this.loadClients()
    },
    setSwitchedData(filterName) {
      switch (filterName) {
        case 'overDue':
          this.filterStatus.backLog = true
          return this.clientOverdue()
        case 'deleted':
          this.statusRestore = true
          return this.allDeletedClients()
        case 'claims':
          this.filterStatus.claims = true
          return this.allClaims()
        case 'contracts':
          this.filterStatus.haveContracts = true
          return this.loadClients(this.fqForLoadClients.fContracts)
      }
    },
    isDeletable(client) {
      if (
        client._id === this.user._id ||
        (client.environments?.length &&
          client.environments[0]?.emlpoyeeRole === 'owner') ||
        !this.$hasPermission('users', 'clients', 'delete')
      ) {
        return false
      }
      return true
    },
    searchClients(search) {
      this.pagination.pageNumber = 1
      this.searchFilter = search
      this.loadClients()
    }
  },
  metaInfo() {
    return {
      title: 'العملاء'
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/material-dashboard/fastactions.scss';
@import '@/styles/material-dashboard/_statistics-cards.scss';
</style>
